import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { rem, fontSize } from "../design-system";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Properties from "../components/Properties";
import Tag from "../components/Tag";
import Link from "../components/Link";

const ViewAllLink = styled(Link)`
  font-size: ${rem(fontSize(100))};
  font-weight: 500;
  text-decoration: underline;
`;

function PropertyTypePage({ data, pageContext }) {
  return (
    <Layout>
      <SEO title="Home" />
      <Properties
        title={
          <>
            Property Type: <Tag>{pageContext.propertyType}</Tag>{" "}
            <ViewAllLink to="/">View all</ViewAllLink>
          </>
        }
        data={data}
      />
    </Layout>
  );
}

export default PropertyTypePage;

export const query = graphql`
  query($propertyType: String!) {
    propertyType: file(
      sourceInstanceName: { eq: "propertyTypes" }
      childMarkdownRemark: { frontmatter: { name: { eq: $propertyType } } }
    ) {
      childMarkdownRemark {
        fields {
          slug
        }
        frontmatter {
          name
          pluralLabel
        }
      }
    }
    properties: allFile(
      filter: {
        childMarkdownRemark: { frontmatter: { type: { eq: $propertyType } } }
      }
    ) {
      edges {
        node {
          childMarkdownRemark {
            html
            frontmatter {
              title
              type
              image
              date(formatString: "MMMM D, YYYY")
              rent
            }
            fields {
              slug
            }
          }
        }
      }
    }
  }
`;
